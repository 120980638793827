import React from "react";
import { useMediaQuery, Grid, Box } from "@mui/material";
import HeroBanner from "../components/HeroBanner";
import About from "../components/About";
import Companies from "../components/Companies";
import Recommendations from "../components/Recommendations";
import ContactMe from "../components/ContactMe";
import Navbar from "../components/NavBar";
import Scopes from "../components/Scopes";
import Footer from "../components/Footer";
import FloatingBar from "../components/FloatingBar";
import { useContent } from "../components/reusable/useContent";
import LoadingAnimation from "../components/reusable/LoadingAnimation";

function Home() {
  const content = useContent();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));

  if (!content) {
    return <LoadingAnimation />;
  }

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <Navbar content={content.navbar} isMobile={isMobile} />
      <FloatingBar content={content} isMobile={isMobile} />
      <Grid container>
        <Grid item xs={12}>
          <HeroBanner content={content.heroBanner} isMobile={isMobile} />
        </Grid>
        <Grid item id="about" xs={12}>
          <About content={content.about} isMobile={isMobile} isTablet={isTablet} />
        </Grid>
        <Grid item id="scopes" xs={12}>
          <Scopes content={content.scope} isMobile={isMobile} />
        </Grid>
        <Grid item id="companies" xs={12}>
          <Companies content={content.companies} isMobile={isMobile} />
        </Grid>
        <Grid
          item
          id="contactme"
          xs={12}
          sx={{ bgcolor: "rgba(74, 105, 123, 0.1)" }}
        >
          <ContactMe content={content.contactMe} isMobile={isMobile} />
        </Grid>
        <Grid item id="footer" xs={12}>
          <Footer content={content} isMobile={isMobile} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
