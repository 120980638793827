import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const ContentContext = createContext("");

// Create a provider component
export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    fetch('/data/content.json')
      .then((response) => response.json())
      .then((data) => setContent(data))
      .catch((error) => console.error('Error fetching the content:', error));
  }, []);

  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  );
};

// Custom hook to use the content
export const useContent = () => {
  return useContext(ContentContext);
};
