import React from "react";
import { Box, Typography, Link, Paper, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/FacebookTwoTone";
import PersonIcon from "@mui/icons-material/Person";
import Logo from "../Images/logo/bullseye_logo.png";
import Dev_Logo from "../Images/logo/developers_logo_white.png";

const Footer = ({ content, isMobile }) => {
  return (
    <Box bgcolor="rgba(74, 105, 123, 0.1)">
      <Paper
        elevation={24}
        sx={{
          minHeight: isMobile ? "150px" : "100px",
          position: "relative",
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px",
          width: "100%",
          backgroundColor: "#333",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 3.5,
          pl: 15,
          pr: 2.5,
        }}
      >
        <Box position="absolute" left={15}>
          <img
            src={Logo}
            alt={content.footer.alt_logo}
            style={{ height: isMobile ? 60 : 80 }}
          />
        </Box>
        <Box position="relative">
          <Box
            display="flex"
            position="absolute"
            sx={{
              color: "whitesmoke",
              minWidth: "210px",
              top: isMobile ? "45px" : "25px",
              left: isMobile ? "-115px" : "0",
            }}
          >
            <Typography variant={isMobile ? "mobileBody2" : "body2"}>
              <Link
                href={content.footer.freePik.href_Pexelslink}
                target="_blank"
                sx={{ color: "inherit", textDecoration: "underline" }}
              >
                {content.footer.Pexels}
              </Link>
            </Typography>
            <Typography
              variant={isMobile ? "mobileBody2" : "body2"}
              sx={{ pl: 0.5 }}
            >
              &
            </Typography>
            <Typography variant={isMobile ? "mobileBody2" : "body2"}>
              <Link
                href={content.footer.freePik.href_FreePiklink}
                target="_blank"
                sx={{ color: "inherit", textDecoration: "underline", pl: 0.5 }}
              >
                {content.footer.Freepik}
              </Link>
            </Typography>
            <Typography
              variant={isMobile ? "mobileBody2" : "body2"}
              sx={{ pl: 0.5 }}
            >
              {content.footer.freePik.Attribution_Text}
            </Typography>
          </Box>
        </Box>

        <Box
          color="whitesmoke"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            bottom={isMobile ? "70%" : "45px"}
            left={isMobile ? "15%" : null}
          >
            <Typography
              variant="body2"
              fontSize="1.2rem"
              pr={0.5}
              color="#FF0000"
              fontWeight="bold"
              fontStyle="italic"
              sx={{
                textShadow:
                  "0px 0px 5px whitesmoke, 0px 0px 5px whitesmoke, 0px 0px 5px whitesmoke",
              }}
            >
              {content.footer.company_Name.part1}
            </Typography>
            <Typography
              variant="body2"
              fontSize="1.2rem"
              pr={1}
              color="#000000"
              fontWeight="bold"
              fontStyle="italic"
              sx={{
                textShadow:
                  "0px 0px 5px whitesmoke, 0px 0px 5px whitesmoke, 0px 0px 5px whitesmoke",
              }}
            >
              {content.footer.company_Name.part2}
            </Typography>
            <Typography variant="body2" pr={0.5}>
              2024
            </Typography>
            <Typography variant="body2"> &copy;</Typography>
            <Typography variant="body2" pl={0.5}>
              Copyrights
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              color: "whitesmoke",
              bottom: isMobile ? "-5px" : "0px",
              left: isMobile ? "50%" : null,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              component={Link}
              href="https://lng-websites.github.io/Landing_Page/"
              target="_blank"
            >
              <img
                title="LnG"
                src={Dev_Logo}
                alt="LnG"
                style={{
                  height: 35,
                  textShadow:
                    "0px 0px 5px whitesmoke, 0px 0px 5px whitesmoke, 0px 0px 5px whitesmoke",
                }}
              />
            </IconButton>
            <Typography variant={isMobile ? "mobileBody1" : "body2"}>
              Developed By
            </Typography>
          </Box>
        </Box>

        <Box display="flex" color="whitesmoke">
          <Box display="flex" alignItems="center" sx={{ pr: "10px" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                color: "white",
              }}
            >
              {content.email}
            </Typography>
            <PersonIcon fontSize="medium" />
          </Box>
          <IconButton
            size="small"
            color="primary"
            component={Link}
            href={content.href_FBlink}
            target="_blank"
            sx={{
              color: "blue",
              backgroundColor: "whitesmoke",
              "&:hover": {
                backgroundColor: "whitesmoke",
              },
            }}
          >
            <FacebookIcon
              fontSize="large"
              sx={{
                transform: "scale(1.6)",
              }}
            />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default Footer;
