import React, { useState, useEffect } from "react";
import { Fab, Zoom } from "@mui/material";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

const FloatingBar = ({content}) => {
  const [visible, setVisible] = useState(false);
  const ScrollToTopButton = styled(Fab)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  }));

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const actions = [
    {
      icon: <MailOutlineRoundedIcon />,
      name: "Send Mail",
      action: (e) => {
        window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&to=${content.email}`,
          "_blank"
        );
      },
    },
    {
      icon: <FacebookRoundedIcon />,
      name: "Facebook",
      action: (e) => {
        window.open(
          content.href_FBlink,
          "_blank"
        );
      },
    },
  ];

  return (
    <Box
      position="fixed"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      bottom={100}
      right={15}
      height="125px"
      zIndex={3}
    >
      <Box display="flex" justifyContent="flex-end">
        <Zoom in={visible}>
          <ScrollToTopButton onClick={handleClick}>
            <ArrowUpwardOutlinedIcon />
          </ScrollToTopButton>
        </Zoom>
      </Box>
      <Box>
        <SpeedDial
          ariaLabel="SpeedDial"
          icon={<MoreVertRoundedIcon />}
          direction="left"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
              sx={{
                color: "blue",
              }}
            />
          ))}
        </SpeedDial>
      </Box>
    </Box>
  );
};

export default FloatingBar;
