import React from "react";
import {
  Box,
  Typography,
  Paper,
  styled,
  CardMedia,
  CardContent,
  IconButton,
} from "@mui/material";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import logo from "../Images/logo/logo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Recommendation = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  marginLeft: 40,
  marginRight: 40,
  textAlign: "left",
  color: theme.palette.text.secondary,
  border: "none",
  zIndex: 1,
  minHeight: 500,
  maxWidth: 400,
}));

const RecommendationCard = ({ recommender, job, company, description }) => (
  <Recommendation>
    <CardMedia sx={{ minHeight: 270 }} image={logo} />
    <CardContent sx={{ minWidth: 270, pt: 2, direction: "ltr" }}>
      <Typography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}>
        {recommender}
      </Typography>
      <Box sx={{ display: "flex", mb: 1 }}>
        <BusinessCenterOutlinedIcon sx={{ mr: 1 }} />
        <Typography variant="body1" color="text.secondary">
          {job}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <BusinessOutlinedIcon sx={{ mr: 1 }} />
        <Typography variant="body1" color="text.secondary">
          {company}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <InfoOutlinedIcon sx={{ mr: 1 }} />
        <Typography variant="body1">
          {description}
        </Typography>
      </Box>
    </CardContent>
  </Recommendation>
);

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <IconButton
      sx={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "-20px",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightIcon sx={{ fontSize: "3rem" }} />
    </IconButton>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <IconButton
      sx={{
        ...style,
        position: "absolute",
        top: "50%",
        right: "-20px",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftIcon sx={{ fontSize: "3rem" }} />
    </IconButton>
  );
}

function Recommendations({ content }) {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: true,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      py={6}
      zIndex={-1}
      minHeight={"715px"}
      sx={{
        width: "100vw",
        backgroundColor: "#9FAFB8",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          pb: 6,
        }}
      >
        {content.title}
      </Typography>
      <Box
        className="slider-container"
        sx={{
          maxWidth: "90vw",
          display: "flex",
          flexDirection: "column",
          pr: 4,
        }}
      >
        <Slider {...settings}>
          {content.content.map((recommendation, i) => (
            <RecommendationCard {...recommendation} key={i} />
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default Recommendations;
