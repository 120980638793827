export const validate = (
  name,
  value,
  setErrors,
  setValidity,
  activeTab,
  tabs
) => {
  let error = null;
  let isValid = true;
  if (!value) {
    error = "שדה זה חובה למילוי";
    isValid = false;
  } else {
    switch (name) {
      case "contactNumber":
        if (!/^\d{10}$/.test(value)) {
          error = "שגיאה במילוי השדה";
          isValid = false;
        }
        break;
      case "contactEmail":
        if (
          !/^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,}(?:\.[a-zA-Z]{2})?$/.test(value)
        ) {
          error = "שגיאה במילוי השדה";
          isValid = false;
        }
        break;
      default:
        break;
    }
  }
  setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));

  const indexToUpdate = activeTab === tabs[0] ? 0 : 1;

  setValidity((prevValidity) => {
    const updatedValidity = [...prevValidity];
    updatedValidity[indexToUpdate] = {
      ...updatedValidity[indexToUpdate],
      [name]: isValid,
    };
    return updatedValidity;
  });
};
