import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../Images/logo/logo.png";

const NavBar = ({ content, isMobile }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleScrollTo = (page) => {
    const section = document.getElementById(page);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
    setDrawerOpen(false); // Close the drawer on selection
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Box
      sx={{ width: "100%", minHeight: "100px", overflow: "hidden", backgroundColor: "#71A4BA" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List sx={{padding:0}}>
        {content.map((page) => (
          <ListItem
            key={page[0]}
            onClick={() => handleScrollTo(page[1])}
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid black",
              "&:hover": { backgroundColor: "rgba(126, 164, 163, 0.7)" },
              padding: "15px 15px",
            }}
          >
            <ListItemText
              primary={page[0]}
              primaryTypographyProps={{
                fontSize: "2rem",
                fontWeight: "bold",
                color: "white",
                textAlign: "center",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: "rgba(126, 164, 163, 0.7)",
        padding: "15px 0",
      }}
    >
      <Toolbar sx={{ minHeight: 80, justifyContent: isMobile ? "space-between" : "left" }}>
        <Box sx={{ display: "flex", pr: 10 }}>
          <img src={Logo} alt="לוגו" style={{ height: 80 }} />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{  position: "relative" }}
            >
              <MenuIcon sx={{ fontSize: "3rem" }} />
            </IconButton>
            <Drawer
              anchor="top"
              transitionDuration={{ enter: 1000, exit: 1000 }}
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              SlideProps={{ direction: "down" }}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex" }}>
            {content.map((page) => (
              <Button
                key={page[0]}
                sx={{
                  my: 2,
                  color: "white",
                  fontSize: "1.5rem",
                  px: 5,
                  transition: "textShadow 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textShadow:
                      "0px 0px 20px whitesmoke, 0px 0px 20px whitesmoke, 0px 0px 30px whitesmoke",
                    color: "black",
                    scale: "1.3",
                  },
                }}
                onClick={() => handleScrollTo(page[1])}
              >
                {page[0]}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
