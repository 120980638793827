import React from "react";
import { Box, Typography, Button } from "@mui/material";
import hero from "../Images/hero-optionals/hero.jpg";

function HeroBanner({ content, isMobile }) {
  return (
    <Box
      sx={{
        position: "relative",
        height: isMobile ? "70vh" : "85vh",
        backgroundImage: `url(${hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        color: "white",
        minHeight: isMobile ? "300px" : "850px",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background:
            "linear-gradient(rgba(0, 30, 300, 0.3), rgba(0, 0, 0, 0.4))",
          zIndex: 1,
        },
        zIndex: 0,
      }}
    >
      <Box sx={{ zIndex: 2 }}>
        <Typography variant={isMobile ? "mobileH1" : "h1"} position={"absolute"} top={"40%"} left={"5%"}>
          {content.title1}
        </Typography>
        <Typography variant={isMobile ? "mobileH1" : "h1"} position={"absolute"} top={"50%"} left={"20%"}>
          {content.title2}
        </Typography>
      </Box>
      <Box sx={{ zIndex: 2 }} position={"absolute"} top={"35%"} left={"38%"}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: isMobile ? 25 : 30,
            pr: isMobile ? 5 : 10,
            pl: isMobile ? 5 : 10,
            pb: 2,
            pt: 2,
            fontSize: "1.3rem",
            fontWeight: "bold",
            backgroundColor: "rgba(209, 49, 36, 0.9)",
            "&:hover": {
              backgroundColor: "rgba(209, 49, 36, 0.9)",
              transform: "scale(1.05)",
              fontWeight: "bold",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
            },
          }}
          type="submit"
          onClick={() => {
            const contactMe = document.getElementById("contactme");
            window.scrollTo({
              top: contactMe.offsetTop,
              behavior: "smooth",
            });
          }}
        >
          <Typography variant={isMobile ? "mobileH5" : "h5"}>{content.buttonContent}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default HeroBanner;
