import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Snackbar, Alert } from "@mui/material";
import { motion } from "framer-motion";
import CompanyForm from "../forms/CompanyForm";
import EmployeeForm from "../forms/EmployeeForm";

import company_pic from "../Images/recruiting.png";
import employee_pic from "../Images/we-are-hiring.png";
import { validate } from "../components/reusable/validate";

const ContactMe = ({ content, isMobile }) => {
  const tabs = content.tabs;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [formData, setFormData] = useState({});
  const [uploadedFile, setUploadedFile] = useState(null);
  const [formValidated, setFormValidated] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const [errors, setErrors] = useState({});
  const [validity, setValidity] = useState([
    {
      companyName: false,
      contactName: false,
      contactPosition: false,
      contactNumber: false,
      contactEmail: false,
      msgContent: true, // Optional
    },
    {
      fullName: false,
      contactNumber: false,
      contactEmail: false,
      desiredPosition: false,
      city: false,
      msgContent: true, // Optional
    },
  ]);

  const [formKey, setFormKey] = useState(0); // Key to force form re-render

  useEffect(() => {
    setFormValidated(
      Object.values(validity[activeTab === tabs[0] ? 0 : 1]).every(
        (isValid) => isValid
      )
    );
  }, [validity, setFormValidated, activeTab, tabs]);

  const handleFormSubmit = async () => {
    if (!formValidated) {
      setAlertMessage(content.alerts.warning);
      setAlertSeverity("warning");
      setAlertOpen(true);
      return;
    }

    const emailjs = require("emailjs-com");
    let message = "";
    Object.keys(formData).forEach((key) => {
      message += `${key}: ${formData[key]}\n`;
    });

    const templateParams = {
      to_name: "Nitay Arie",
      from_name: formData["contactName"],
      message: message,
    };

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        templateParams.attachment = {
          content: base64String,
          filename: uploadedFile.name,
          type: uploadedFile.type,
          disposition: "attachment",
        };
      };
      reader.readAsDataURL(uploadedFile);
    }

    emailjs
      .send(
        "service_uca095o",
        "template_41950g7",
        templateParams,
        "Lq0yZHXnz-r5sgcA7"
      )
      .then(
        (response) => {
          setAlertMessage(content.alerts.success);
          setAlertSeverity("success");
          setAlertOpen(true);
          setUploadedFile(null);
          setFormValidated(false);
          if (activeTab === tabs[0]) {
            setFormData({
              companyName: "",
              contactName: "",
              contactPosition: "",
              contactNumber: "",
              contactEmail: "",
              msgContent: "",
            });
          } else {
            setFormData({
              fullName: "",
              contactNumber: "",
              contactEmail: "",
              desiredPosition: "",
              city: "",
              msgContent: "",
            });
          }
          setFormKey((prevKey) => prevKey + 1);
        },
        (err) => {
          setAlertMessage(content.alerts.error);
          setAlertSeverity("error");
          setAlertOpen(true);
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate(name, value, setErrors, setValidity, activeTab, tabs);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <Box
      sx={{
        minHeight: "750px",
        margin: "auto",
        top: "70px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        {tabs.map((tab) => (
          <Button
            key={tab}
            onClick={() => setActiveTab(tab)}
            sx={{
              backgroundColor: activeTab === tab ? "#9FAFB8" : "grey.400",
              color: activeTab === tab ? "black" : "white",
              zIndex: activeTab === tab ? 1 : 0,
              fontSize: isMobile ? "1.2rem" : "1.5rem",
              px: 4,
              m: 0,
              transform: activeTab === tab ? "scale(1.1)" : "scale(1)",
              "&:hover": {
                backgroundColor: activeTab === tab ? "#9FAFB8" : "grey.500",
              },
            }}
          >
            {tab}
          </Button>
        ))}
      </Box>
      <motion.div
        initial={{ opacity: 0, x: window.innerHeight }}
        animate={{ opacity: 1, x: 25 }}
        transition={{ duration: 0.75 }}
        key={`${activeTab}-form`}
        style={{
          position: "relative",
          right: "2%",
          minHeight: "525px",
          display: "flex",
          alignSelf: "center",
          backgroundColor: "whitesmoke",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Paper
          elevation={24}
          sx={{
            minWidth: isMobile ? "90%" : "1150px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "rgba(74, 105, 123, 0.5)",
            zIndex: -1,
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{
              padding: "20px",
              zIndex: 1,
              opacity: 0.8,
              width: isMobile ? "75%" : "50%",
              position: "relative",
              flexDirection: "column",
            }}
          >
            {activeTab === tabs[0] ? (
              <CompanyForm
                onChange={handleChange}
                setFormValidated={setFormValidated}
                formData={formData}
                errors={errors}
                formKey={formKey}
                isMobile={isMobile}
              />
            ) : (
              <EmployeeForm
                onChange={handleChange}
                // setFormValidated={setFormValidated}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                formData={formData}
                errors={errors}
                setErrors={setErrors}
                formKey={formKey}
                isMobile={isMobile}
              />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              height: isMobile
                ? activeTab === tabs[0]
                  ? "300px"
                  : "275px"
                : "600px",
              backgroundImage: `url(${
                activeTab === tabs[0] ? company_pic : employee_pic
              })`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              zIndex: 0,
              mb: isMobile ? "90px" : "0px",
              mt: isMobile ? "100px" : "0",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: isMobile
                ? activeTab === tabs[0]
                  ? "42%"
                  : "37%"
                : "15px",
              left: isMobile
                ? "53%"
                : `${activeTab === tabs[0] ? "40%" : "70%"}`,
              transform: isMobile ? "translateX(-50%)" : "none",
              zIndex: 3,
            }}
          >
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              sx={{
                zIndex: 1,
                backgroundColor: "rgba(87, 156, 249, 0.8)",
                "&:hover": {
                  backgroundColor: "rgba(87, 156, 249, 0.9)",
                  transform: "scale(1.1)",
                  transition: "all 0.1s ease-in-out",
                },
                py: 1,
                px: 8,
                fontSize: isMobile ? "1.1rem" : "1.3rem",
                fontWeight: "bold",
                fontFamily: "'Segoe UI'",
                borderRadius: "15px",
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
              }}
            >
              שלח
            </Button>
          </Box>
        </Paper>
      </motion.div>
      <Snackbar
        position="absolute"
        open={alertOpen}
        onClose={handleCloseAlert}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          sx={{
            width: "100%",
            position: "relative",
            bottom: isMobile ? "400px" : "175px",
            right: isMobile ? "0px" : "50px",
            zIndex: 1,
            textAlign: "center",
            fontSize: isMobile ? "0.8rem" : "1rem",
          }}
          elevation={24}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactMe;
