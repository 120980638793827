import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import TransportationImage from "../Images/scopes/icons/truck.png";
import MoneyBagImage from "../Images/scopes/icons/money-bag-icon.png";
import ServiceImage from "../Images/scopes/icons/service-tools-icon.png";
import ChartArrowUpImage from "../Images/scopes/icons/chart-arrow-up-icon.png";
import AdminImage from "../Images/scopes/icons/admin-icon.png";
import fistbumpImage from "../Images/scopes/fistbump.jpg";

const Scopes = ({ content, isMobile }) => {
  const images = [
    TransportationImage,
    MoneyBagImage,
    ServiceImage,
    AdminImage,
    ChartArrowUpImage,
  ];

  const images_dict = content.cards.reduce((acc, card, index) => {
    acc[card.name] = images[index];
    return acc;
  }, {});

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        padding: "40px 15%",
        minHeight: "600px",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `linear-gradient(to bottom, rgba(74, 145, 163, 0.1), rgba(104, 145, 170, 0.8)), url(${fistbumpImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          zIndex: -1,
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant={isMobile ? "mobileH2" : "h2"}
          sx={{
            mt: 5,
          }}
        >
          {content.title}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 10,
        }}
      />
      <Grid
        container
        spacing={4}
        justifyContent="center"
        flexDirection={isMobile ? "column" : "row"}
      >
        {content.cards.map((card, index) => (
          <Grid
            item
            xs={12 / content.cards.length}
            key={index}
            sx={{
              position: "relative",
              right: isMobile ? "-7%" : "10%",
              transform: "translateX(50%)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                minWidth: "170px",
                height: "100%",
                "&:hover": {
                  transform: isMobile ? "" : "scale(1.1)",
                },
              }}
            >
              <img
                src={images_dict[card.name]}
                alt={card.name}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                  marginBottom: "15px",
                }}
              />
              <Typography
                variant={isMobile ? "mobileH6" : "h6"}
                gutterBottom
                sx={{
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                {card.name}
              </Typography>
              <Box alignSelf="start" pl={"25%"}>
                <ul
                  style={{
                    paddingInlineStart: "0px",
                    margin: "0",
                  }}
                >
                  {card.description.map((desc, i) => (
                    <li key={i}>
                      <Typography variant="body1">{desc}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Scopes;
