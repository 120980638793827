import React from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import ember from "../Images/company-logos/ember.png";
import chemipal from "../Images/company-logos/chemipal.png";
import ups from "../Images/company-logos/ups.png";
import siram from "../Images/company-logos/siram.png";
import pasternakShoham from "../Images/company-logos/pasternak_shoham.png";
import orsher from "../Images/company-logos/orsher.png";
import sonigo from "../Images/company-logos/sonigo.png";
import vpg from "../Images/company-logos/vpg.png";
import mentfield from "../Images/company-logos/mentfield.png";
import amisraGas from "../Images/company-logos/amisra_gas.png";
import ezraKaduri from "../Images/company-logos/ezra_kaduri.png";
import teatronHashaa from "../Images/company-logos/teatron_hashaa.png";

const rectImageHeight = "80px";
const squareImageHeight = "115px";
const company_logos = [
  { logo: ember, alt: "אמבר", imageHeight: squareImageHeight },
  { logo: chemipal, alt: "כמיפל", imageHeight: rectImageHeight },
  { logo: amisraGas, alt: "אמישראגז", imageHeight: rectImageHeight },
  { logo: ups, alt: "ups", imageHeight: squareImageHeight },
  { logo: siram, alt: "Paro Swiss", imageHeight: rectImageHeight },
  {
    logo: pasternakShoham,
    alt: "פסטרנק שוהם",
    imageHeight: squareImageHeight,
  },
  { logo: orsher, alt: "אורשר", imageHeight: rectImageHeight },
  { logo: mentfield, alt: "Mentfield", imageHeight: rectImageHeight },
  { logo: sonigo, alt: "Sonigo", imageHeight: squareImageHeight },
  { logo: vpg, alt: "VPG", imageHeight: squareImageHeight },
  { logo: ezraKaduri, alt: "עזרא כדורי", imageHeight: squareImageHeight },
  { logo: teatronHashaa, alt: "viral", imageHeight: squareImageHeight },
];
const scrollSpeed = 50; // Adjust the speed of the scroll (higher is slower)

const Container = styled(Box)`
  display: flex;
  overflow: hidden;
  width: 80%;
  padding: 10px;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
`;

const ScrollWrapper = styled(Box)`
  display: flex;
  animation: scroll ${scrollSpeed}s linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const ImageContainer = styled(Box)`
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Companies = ({ content, isMobile }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight={isMobile ? "250px" : "380px"}
      sx={{
        backgroundColor: "rgba(74, 105, 123, 0.1)",
      }}
    >
      <Typography
        variant={isMobile ? "mobileH2" : "h2"}
        sx={{
          pb: isMobile ? 2 : 10,
        }}
      >
        {content.title}
      </Typography>
      <Container>
        <ScrollWrapper>
          <ImageContainer>
            {company_logos.map((company, index) => (
              <img
                key={index}
                title={company.alt}
                src={company.logo}
                alt={company.alt}
                height={company.imageHeight}
              />
            ))}
          </ImageContainer>
          <ImageContainer>
            {company_logos.map((company, index) => (
              <img
                key={index}
                title={company.alt}
                src={company.logo}
                alt={company.alt}
                height={company.imageHeight}
              />
            ))}
          </ImageContainer>
        </ScrollWrapper>
      </Container>
    </Box>
  );
};

export default Companies;
