import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#577CF9", // Primary color
    },
    secondary: {
      main: "#9FAFB8", // Secondary color
    },
    text: {
      primary: "#222", // Primary text color
      secondary: "#333", // Secondary text color
    },
  },
  typography: {
    fontFamily: "Varela Round",
    h1: {
      fontSize: "4.3rem",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    h2: {
      fontSize: "4rem",
      fontWeight: "bold",
      color: "#222",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.7rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.3rem",
      color: "#222",
      fontWeight: "bold",
      padding: "5px 10px",
      backgroundColor: "#f0f0f0",
      borderRadius: "50px",
    },
    body1: {
      fontSize: "1rem",
      color: "#666",
    },
    body2: {
      fontSize: "1rem",
    },
    mobileH1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    mobileH2: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#222",
    },
    mobileH3: {
      fontSize: "1.3rem",
    },
    mobileH4: {
      fontSize: "1.2rem",
    },
    mobileH5: {
      fontSize: "1.1rem",
    },
    mobileH6: {
      fontSize: "1rem",
      color: "#222",
      fontWeight: "bold",
      padding: "5px 10px",
      backgroundColor: "#f0f0f0",
      borderRadius: "50px",
    },
    mobileBody1: {
      fontSize: "0.9rem",
    },
    mobileBody2: {
      fontSize: "0.85rem",
    },
  },
});

export default theme;
