import React from "react";
import { Box } from "@mui/material";
import InputField from "../components/reusable/InputField";

function CompanyForm({
  onChange,
  formData,
  errors,
  formKey,
  isMobile,
}) {

  return (
    <form
      id="company_form"
      key={formKey}
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <InputField
        name="companyName"
        label="שם החברה"
        value={formData.companyName || ""}
        onChange={onChange}
        sx={{ width: isMobile ? "90%" : "500px", mx: isMobile ? 4.5 : 2 }}
        error={Boolean(errors.companyName)}
        helperText={errors.companyName}
      />
      <Box
        sx={{
          mx: 2,
          my: 3,
          width: "500px",
          display: isMobile ? "flex" : "inline-flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: isMobile ? 0 : "20px",
        }}
      >
        <Box sx={{ mb: isMobile ? 3 : 0 }}>
          <InputField
            name="contactName"
            label="שם איש הקשר"
            value={formData.contactName || ""}
            onChange={onChange}
            error={Boolean(errors.contactName)}
            helperText={errors.contactName}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
          />
        </Box>
        <Box>
          <InputField
            name="contactPosition"
            label="תפקיד איש הקשר"
            value={formData.contactPosition || ""}
            onChange={onChange}
            error={Boolean(errors.contactPosition)}
            helperText={errors.contactPosition}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mx: 2,
          width: "500px",
          display: isMobile ? "flex" : "inline-flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Box>
          <InputField
            name="contactNumber"
            label="מספר נייד"
            value={formData.contactNumber || ""}
            onChange={onChange}
            error={Boolean(errors.contactNumber)}
            helperText={errors.contactNumber}
            sx={{
              width: isMobile ? "70%" : "240px",
              mx: isMobile ? 2.5 : 0,
              mb: isMobile ? 0.5 : 0,
            }}
          />
        </Box>
        <Box>
          <InputField
            name="contactEmail"
            label="כתובת אימייל"
            value={formData.contactEmail || ""}
            onChange={onChange}
            error={Boolean(errors.contactEmail)}
            helperText={errors.contactEmail}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
          />
        </Box>
      </Box>
      <InputField
        name="msgContent"
        label="תוכן ההודעה"
        value={formData.msgContent || ""}
        onChange={onChange}
        multiline
        rows={4}
        sx={{
          my: 3,
          mx: isMobile ? 4.5 : 2,
          width: isMobile ? "90%" : "500px",
        }}
      />
    </form>
  );
}

export default CompanyForm;
