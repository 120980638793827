import { TextField, styled } from "@mui/material";

const InputField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  variant: 'outlined',
  width: '240px',
  inputProps: {
    style: {
      textAlign: 'right',
    },
  }
}));

export default InputField