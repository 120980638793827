import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import Logo from "../../Images/logo/logo.png";


// Define the keyframes for the animation
const fillOpacity = keyframes`
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
`;

// Styled component for the overlay
const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.95)', // Adjust color as needed
  transformOrigin: 'bottom',
  animation: `${fillOpacity} 4s ease infinite`,
}));

const LoadingAnimation = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative', width: '200px', height: '200px' }}>
        <img
          src={Logo}
          alt="Logo"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
        <Overlay />
      </Box>
    </Box>
  );
};

export default LoadingAnimation;
